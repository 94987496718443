import {individualTemplateColor} from "../../util/ColorUtil";
import Image from "next/image";
import { getEditorColors, isItUndefined, modifyLinks } from "../../util/DataUtil";
import { cleanHTML } from "../utils/htmlUtils";

const CustomerWhySuperGraphic = ({data, individualPageForceBackgroundColor}) => {
    let items = data?.field_customer_items?.title_text_paragraph_type;
    let image = data?.field_customer_image[0];

    const emphasizedTextColor = getEditorColors(isItUndefined(data?.field_customer_title), isItUndefined(data?.field_customer_subtitle)).filter(x => x)[0];

    const renderItems = (items) => {
        if (!items){
            return ''
        }

        return items.map((item,key) => {
            return <div key={`item-index-${key}`} className={'item'}>
                <span className={'title'}>{item?.field_title_p[0]?.value}</span>
                <span className={`text ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html : item?.field_text_p[0]?.value}}/>
            </div>
        })
    }

    return <div className={'customer-why-super-graphic'} style={{backgroundColor : individualTemplateColor(data?.field_customer_background[0]?.value, individualPageForceBackgroundColor)}}>
        <div className={'inner-container'}>
            <h2 className={`${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html: cleanHTML(modifyLinks(data?.field_customer_title[0]?.value))}}/>
            <span className={`subtitle ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html : cleanHTML(modifyLinks(data?.field_customer_subtitle[0]?.value))}}></span>
            <div className={'bottom'}>
                {image ? <div className={'images'}>
                    <Image alt={'main-image'} layout={'fill'} className="main-image" src={image} />
                    <img alt={'overlay'} className={'overlay'} src={'/images/why-super-overlay.png'} />
                </div> : <></>
                }
                <div className={'items'}>
                    {renderItems(items)}
                </div>
            </div>
        </div>
    </div>
}

export default CustomerWhySuperGraphic;
